export const rawDetectorJsonSource =
"[\n" +
    "  {\n" +
    "    \"Id\": \"e5ecc01e-abc1-40fd-a3ac-71ff759b9df9\",\n" +
    "    \"Name\": \"353-600021\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeSlave\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P41MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"139398af-887c-4564-aba0-ad9cf4f3829f\",\n" +
    "    \"Name\": \"353-600121\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"oneChannel\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P42MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"66794d39-7c7d-4694-a9b4-fc653cf98cb1\",\n" +
    "    \"Name\": \"353-601021\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeSlave\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P41MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"e7cfce75-31a7-4c55-8bce-f3ac86c0453f\",\n" +
    "    \"Name\": \"353-601121\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"oneChannel\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P42MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"0bb6b805-a230-4173-abcd-4751edcbd08d\",\n" +
    "    \"Name\": \"353-601221\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"twoChannel\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P42MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"b380e2c6-cf23-45d6-b9f8-bc4c56ea9799\",\n" +
    "    \"Name\": \"353-602021\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeSlave\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P41MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"c71796bd-b6e6-424a-b3f4-0963f6ff0302\",\n" +
    "    \"Name\": \"353-602121\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"oneChannel\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P42MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"57fbb2be-e960-4b2d-9197-d492aa1333e5\",\n" +
    "    \"Name\": \"353-602221\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"twoChannel\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P42MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"5e44da87-94d3-429f-bb8b-5c323ea66af5\",\n" +
    "    \"Name\": \"353-650021\",\n" +
    "    \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "    \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P46MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"4e13c2bb-d6a3-45a8-ba1a-51b16290ebba\",\n" +
    "    \"Name\": \"353-650321\",\n" +
    "    \"DetectorFamily\": \"dali2Detector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"standard\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P47MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"5fd371f5-f6a6-463a-8694-d6bc53e94f6c\",\n" +
    "    \"Name\": \"353-651021\",\n" +
    "    \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "    \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P46MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"b5c42413-a1c3-4f41-a7a3-a2ace3a6df5b\",\n" +
    "    \"Name\": \"353-651321\",\n" +
    "    \"DetectorFamily\": \"dali2Detector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"standard\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P47MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"059b7201-da9f-4a16-8781-b4b42c2ebff2\",\n" +
    "    \"Name\": \"353-651421\",\n" +
    "    \"DetectorFamily\": \"dali2Detector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"daliComfort\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P48MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"990f311c-8c6f-466f-ae10-825ecbb71608\",\n" +
    "    \"Name\": \"353-652021\",\n" +
    "    \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "    \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P46MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"d803e945-05c6-4d2a-b14d-02c085b55892\",\n" +
    "    \"Name\": \"353-652321\",\n" +
    "    \"DetectorFamily\": \"dali2Detector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"standard\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P47MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"2ce22ed7-1217-4197-aaa6-ad522e7d0221\",\n" +
    "    \"Name\": \"353-652421\",\n" +
    "    \"DetectorFamily\": \"dali2Detector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"daliComfort\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P48MR\",\n" +
    "    \"Range\": 14,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"a0e4055b-dbe1-40ab-a2f1-caadcc45d107\",\n" +
    "    \"Name\": \"353-700021\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeSlave\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P41LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"4394eb8e-7092-47d9-979c-33ada900672c\",\n" +
    "    \"Name\": \"353-700121\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"oneChannel\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P42LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"ef43a417-68dc-4f74-a657-4d97d8a1b032\",\n" +
    "    \"Name\": \"353-701021\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeSlave\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P41LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"555cca43-8758-43fa-a753-f98fdd35f1bf\",\n" +
    "    \"Name\": \"353-701121\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"oneChannel\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P42LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"bf02c1c2-0a75-4d55-85c3-8ccc7819fdbc\",\n" +
    "    \"Name\": \"353-701221\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"twoChannel\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P42LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"fe506fad-464c-41e3-8ce3-c3251db94718\",\n" +
    "    \"Name\": \"353-702021\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeSlave\",\n" +
    "    \"DetectorOutputInfo\": \"oneChannel\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P41LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"ebbd8bd5-199c-499b-b15a-220def33380a\",\n" +
    "    \"Name\": \"353-702121\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"oneChannel\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P42LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"87c2b99f-a072-4a38-8a2e-e69c87b13c9b\",\n" +
    "    \"Name\": \"353-702221\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"twoChannel\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P42LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"d2e8e883-e07e-4fe7-959d-1a1e6cb41929\",\n" +
    "    \"Name\": \"353-750021\",\n" +
    "    \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "    \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P46LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"87818cdd-64aa-4040-aec3-4a68753c3d7a\",\n" +
    "    \"Name\": \"353-750321\",\n" +
    "    \"DetectorFamily\": \"dali2Detector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"standard\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P47LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"a929284c-e1ab-4873-bfd2-f062154010a3\",\n" +
    "    \"Name\": \"353-751021\",\n" +
    "    \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "    \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P46LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"43c3fbcf-3d58-477a-be70-c970dc3e5486\",\n" +
    "    \"Name\": \"353-751321\",\n" +
    "    \"DetectorFamily\": \"dali2Detector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"standard\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P47LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"d73d381c-ba55-4ec0-98b2-13a7ac5cec35\",\n" +
    "    \"Name\": \"353-751421\",\n" +
    "    \"DetectorFamily\": \"dali2Detector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"daliComfort\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P48LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"flushMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"83ccc5be-9053-46b8-a6a7-651944a6caaf\",\n" +
    "    \"Name\": \"353-752021\",\n" +
    "    \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "    \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P46LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"326ce55e-dcbc-408c-886c-a8cb019d80de\",\n" +
    "    \"Name\": \"353-752321\",\n" +
    "    \"DetectorFamily\": \"dali2Detector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"standard\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P47LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"49d91a4b-a6f6-44f6-afc9-ff8fb6863133\",\n" +
    "    \"Name\": \"353-752421\",\n" +
    "    \"DetectorFamily\": \"dali2Detector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"daliComfort\",\n" +
    "    \"DetectorDescription\": \"presenceDectector360\",\n" +
    "    \"DetectorShortDescription\": \"P48LR\",\n" +
    "    \"Range\": 32,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"3c18262c-1d32-4f13-a251-5bf7fb1fd32f\",\n" +
    "    \"Name\": \"353-802011\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeSlave\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"motionDectector360\",\n" +
    "    \"DetectorShortDescription\": \"M41HC\",\n" +
    "    \"Range\": 40,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"173d4496-a4f4-4026-9caf-f23a395d7624\",\n" +
    "    \"Name\": \"353-802111\",\n" +
    "    \"DetectorFamily\": \"230VDectector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"oneChannel\",\n" +
    "    \"DetectorDescription\": \"motionDectector360\",\n" +
    "    \"DetectorShortDescription\": \"M42HC\",\n" +
    "    \"Range\": 40,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"7250b615-a237-459e-b0af-bf4c0baf4719\",\n" +
    "    \"Name\": \"353-852011\",\n" +
    "    \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "    \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "    \"DetectorOutputInfo\": \"\",\n" +
    "    \"DetectorDescription\": \"motionDectector360\",\n" +
    "    \"DetectorShortDescription\": \"M46HC\",\n" +
    "    \"Range\": 40,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "  {\n" +
    "    \"Id\": \"5eae4a84-5c7d-41b7-bb5c-a6850fb7af46\",\n" +
    "    \"Name\": \"353-852411\",\n" +
    "    \"DetectorFamily\": \"dali2Detector\",\n" +
    "    \"DetectorType\": \"detectorTypeMaster\",\n" +
    "    \"DetectorOutputInfo\": \"daliComfort\",\n" +
    "    \"DetectorDescription\": \"motionDectector360\",\n" +
    "    \"DetectorShortDescription\": \"M48HC\",\n" +
    "    \"Range\": 40,\n" +
    "    \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "  },\n" +
    "	{\n" +
	"	  \"Id\": \"171796bd-b6e6-424a-b3f4-0963f6ff0302\",\n" +
	"	  \"Name\": \"353-602111\",\n" +
	"	  \"DetectorFamily\": \"230VDectector\",\n" +
	"	  \"DetectorType\": \"detectorTypeMaster\",\n" +
	"	  \"DetectorOutputInfo\": \"oneChannel\",\n" +
	"	  \"DetectorDescription\": \"motionDectector360\",\n" +
	"	  \"DetectorShortDescription\": \"M42MR\",\n" +
	"	  \"Range\": 14,\n" +
	"	  \"DetectorMountDescription\": \"surfaceMounting\"\n" +
	"	},\n" +
	"	{\n" +
	"	  \"Id\": \"1bbd8bd5-199c-499b-b15a-220def33380a\",\n" +
	"	  \"Name\": \"353-702111\",\n" +
	"	  \"DetectorFamily\": \"230VDectector\",\n" +
	"	  \"DetectorType\": \"detectorTypeMaster\",\n" +
	"	  \"DetectorOutputInfo\": \"oneChannel\",\n" +
	"	  \"DetectorDescription\": \"motionDectector360\",\n" +
	"	  \"DetectorShortDescription\": \"M42LR\",\n" +
	"	  \"Range\": 32,\n" +
	"	  \"DetectorMountDescription\": \"surfaceMounting\"\n" +
	"	},\n" +
	"	{\n" +
	"	  \"Id\": \"239398af-887c-4564-aba0-ad9cf4f3829f\",\n" +
	"	  \"Name\": \"353-600111\",\n" +
	"	  \"DetectorFamily\": \"230VDectector\",\n" +
	"	  \"DetectorType\": \"detectorTypeMaster\",\n" +
	"	  \"DetectorOutputInfo\": \"oneChannel\",\n" +
	"	  \"DetectorDescription\": \"motionDectector360\",\n" +
	"	  \"DetectorShortDescription\": \"M42MR\",\n" +
	"	  \"Range\": 14,\n" +
	"	  \"DetectorMountDescription\": \"flushMountingBox\"\n" +
	"	},\n" +
	"	{\n" +
	"	  \"Id\": \"17cfce75-31a7-4c55-8bce-f3ac86c0453f\",\n" +
	"	  \"Name\": \"353-601111\",\n" +
	"	  \"DetectorFamily\": \"230VDectector\",\n" +
	"	  \"DetectorType\": \"detectorTypeMaster\",\n" +
	"	  \"DetectorOutputInfo\": \"oneChannel\",\n" +
	"	  \"DetectorDescription\": \"motionDectector360\",\n" +
	"	  \"DetectorShortDescription\": \"M42MR\",\n" +
	"	  \"Range\": 14,\n" +
	"	  \"DetectorMountDescription\": \"flushMounting\"\n" +
	"	},\n" +
	"	{\n" +
	"	  \"Id\": \"1394eb8e-7092-47d9-979c-33ada900672c\",\n" +
	"	  \"Name\": \"353-700111\",\n" +
	"	  \"DetectorFamily\": \"230VDectector\",\n" +
	"	  \"DetectorType\": \"detectorTypeMaster\",\n" +
	"	  \"DetectorOutputInfo\": \"oneChannel\",\n" +
	"	  \"DetectorDescription\": \"motionDectector360\",\n" +
	"	  \"DetectorShortDescription\": \"M42LR\",\n" +
	"	  \"Range\": 32,\n" +
	"	  \"DetectorMountDescription\": \"flushMountingBox\"\n" +
	"	},\n" +
	"	{\n" +
	"	  \"Id\": \"155cca43-8758-43fa-a753-f98fdd35f1bf\",\n" +
	"	  \"Name\": \"353-701111\",\n" +
	"	  \"DetectorFamily\": \"230VDectector\",\n" +
	"	  \"DetectorType\": \"detectorTypeMaster\",\n" +
	"	  \"DetectorOutputInfo\": \"oneChannel\",\n" +
	"	  \"DetectorDescription\": \"motionDectector360\",\n" +
	"	  \"DetectorShortDescription\": \"M42LR\",\n" +
	"	  \"Range\": 32,\n" +
	"	  \"DetectorMountDescription\": \"flushMounting\"\n" +
	"	},\n" +
    "	{\n" +
	"	  \"Id\": \"155cca43-8758-11aa-a753-f98fdd35f1bf\",\n" +
	"	  \"Name\": \"353-652521\",\n" +
	"	  \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
	"	  \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
	"	  \"DetectorOutputInfo\": \"oneChannel\",\n" +
	"	  \"DetectorDescription\": \"presenceDectector360\",\n" +
	"	  \"DetectorShortDescription\": \"P49MR\",\n" +
	"	  \"Range\": 13,\n" +
	"	  \"DetectorMountDescription\": \"surfaceMounting\"\n" +
	"	},\n" +
        "	{\n" +
    "	  \"Id\": \"155cca43-8758-21aa-a753-f98fdd35f1bf\",\n" +
    "	  \"Name\": \"353-750521\",\n" +
    "	  \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "	  \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "	  \"DetectorOutputInfo\": \"\",\n" +
    "	  \"DetectorDescription\": \"presenceDectector360\",\n" +
    "	  \"DetectorShortDescription\": \"P49LR\",\n" +
    "	  \"Range\": 37,\n" +
    "	  \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "	},\n" +
    "	{\n" +
    "	  \"Id\": \"155cca43-8758-31aa-a753-f98fdd35f1bf\",\n" +
    "	  \"Name\": \"353-751521\",\n" +
    "	  \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "	  \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "	  \"DetectorOutputInfo\": \"\",\n" +
    "	  \"DetectorDescription\": \"presenceDectector360\",\n" +
    "	  \"DetectorShortDescription\": \"P49LR\",\n" +
    "	  \"Range\": 37,\n" +
    "	  \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "	},\n" +
    "	{\n" +
    "	  \"Id\": \"155cca43-8758-41aa-a753-f98fdd35f1bf\",\n" +
    "	  \"Name\": \"353-651521\",\n" +
    "	  \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "	  \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "	  \"DetectorOutputInfo\": \"\",\n" +
    "	  \"DetectorDescription\": \"presenceDectector360\",\n" +
    "	  \"DetectorShortDescription\": \"P49MR\",\n" +
    "	  \"Range\": 13,\n" +
    "	  \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "	},\n" +
    "	{\n" +
    "	  \"Id\": \"155cca43-8758-43aa-a753-f98fdd35f1bf\",\n" +
    "	  \"Name\": \"353-650521\",\n" +
    "	  \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "	  \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "	  \"DetectorOutputInfo\": \"\",\n" +
    "	  \"DetectorDescription\": \"presenceDectector360\",\n" +
    "	  \"DetectorShortDescription\": \"P49MR\",\n" +
    "	  \"Range\": 13,\n" +
    "	  \"DetectorMountDescription\": \"flushMountingBox\"\n" +
    "	},\n" +
    "	{\n" +
    "	  \"Id\": \"155cca43-8758-51aa-a753-f98fdd35f1bf\",\n" +
    "	  \"Name\": \"353-752521\",\n" +
    "	  \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "	  \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "	  \"DetectorOutputInfo\": \"\",\n" +
    "	  \"DetectorDescription\": \"presenceDectector360\",\n" +
    "	  \"DetectorShortDescription\": \"P49LR\",\n" +
    "	  \"Range\": 37,\n" +
    "	  \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "	},\n" +
    "	{\n" +
    "	  \"Id\": \"155cca43-8758-61aa-a753-f98fdd35f1bf\",\n" +
    "	  \"Name\": \"353-852511\",\n" +
    "	  \"DetectorFamily\": \"dali2DetectorBMS\",\n" +
    "	  \"DetectorType\": \"detectorTypeDaliBMS\",\n" +
    "	  \"DetectorOutputInfo\": \"\",\n" +
    "	  \"DetectorDescription\": \"motionDectector360\",\n" +
    "	  \"DetectorShortDescription\": \"M49HC\",\n" +
    "	  \"Range\": 53,\n" +
    "	  \"DetectorMountDescription\": \"surfaceMounting\"\n" +
    "	}\n" +
"]";
