import React, { useState } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@material-ui/core";
import { ISensorSetting } from "../../../models/SensorSetting";
import SensorDataComponent from "../../../common/components/SensorDataComponent/SensorDataComponent";
import useTypographyStyles from "../../../common/styles/typography";
import useButtonStyles from "../../../common/styles/buttonStyles";
import useStyles from "./ProjectDetails.style";
import { getDetectorTypeFormatted } from "../../../data-services/Templates/TemplateMapper";
import { useAuth } from "../../../context/context";

interface ISettingDetails {
  setting: ISensorSetting;
  closeModal: () => void;
  openExportModal: () => void;
}

const ProjectDetails: React.FC<ISettingDetails> = (props) => {
  const classes = useStyles();
  const typoClasses = useTypographyStyles();
  const buttonStyles = useButtonStyles();
  const timeZone = moment.tz.guess();
  const { t } = useTranslation(["translation"]);
  const { setting, closeModal, openExportModal } = props;

  const handleOpenExportModal = () => {
    closeModal();
    openExportModal();
  };
  //debugger;
  return (
    <>
      <Grid item className={classes.closeIcon} onClick={closeModal}>
        <img src="/img/close.svg" alt="close" />
      </Grid>
      <Grid item>
        <Typography className={typoClasses.headerThreeBlack} component="h3">
          {getDetectorTypeFormatted(setting, t)}
        </Typography>
      </Grid>
      <Grid item className={classes.list}>
        <List component="nav" aria-labelledby="nested-list-subheader">
          <ListItem button disableRipple={true}className={classes.infoItemCursorDefault}>
            <ListItemText
              classes={{
                primary: typoClasses.modalLabelCursorDefault,
                secondary: typoClasses.modalContentCursorDefault,
              }}
              primary={t("translation:Detectors_search.tableHeadings.detectorName")}
              secondary={setting.customName || '-'}
            />
          </ListItem>
          <ListItem button disableRipple={true}className={classes.infoItemCursorDefault}>
           
            <ListItemText
              classes={{
                primary: typoClasses.modalLabelCursorDefault,
                secondary: typoClasses.modalContentCursorDefault,
              }}
              primary={t("translation:common.modals.MACaddress")}
              secondary={setting.macAddress?.match(/.{1,2}/g)?.join(":")}
            />
          </ListItem>

          <ListItem button disableRipple={true}className={classes.infoItemCursorDefault}>
            <ListItemText
              classes={{
                primary: typoClasses.modalLabelCursorDefault,
                secondary: typoClasses.modalContentCursorDefault,
              }}
              primary={t("translation:common.modals.projectName")}
              secondary={setting.projectName}
            />
          </ListItem>
          <ListItem button disableRipple={true}className={classes.infoItemCursorDefault}>
            <ListItemText
              classes={{
                primary: typoClasses.modalLabelCursorDefault,
                secondary: typoClasses.modalContentCursorDefault,
              }}
              primary={t("translation:common.modals.lastUpload")}
              secondary={`${moment
                .tz(setting.uploadedAt, timeZone)
                .format("DD.MM.YYYY")} `}
            />
          </ListItem>
          <SensorDataComponent detector={setting} isSensorSetting={true}/>
        </List>
      </Grid>
      <Grid item className={classes.buttonsContainer}>
        <Button
          className={`${buttonStyles.lightButtonWithBorder} ${classes.spaceBetween}`}
          onClick={closeModal}
        >
          {t("translation:common.closeButton")}
        </Button>
        <Button
          className={buttonStyles.primaryButton14px}
          onClick={handleOpenExportModal}
        >
          {t("translation:common.export")}
        </Button>
      </Grid>
    </>
  );
};

export default ProjectDetails;
