import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import Check from "../Check";
import useStyles from "./CheckAll.style";

interface ICheckAll {
  allItemIds: string[];
  selectedItemIds: string[];
  setSelectedItemsIds(param: string[]): void;
  fetchData?(): void;
}

const CheckAll: React.FC<ICheckAll> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["translation"]);

  const { allItemIds, selectedItemIds, setSelectedItemsIds } = props;

  const toggleSelectAllItems = () => {

    if (selectedItemIds.length === allItemIds.length) {
      setSelectedItemsIds([]);
    } else {
      setSelectedItemsIds(allItemIds);
    }

    if (props.fetchData) {
      props.fetchData();
    }
  };

  return (
    <Button
      variant="contained"
      className={classes.button}
      startIcon={<Check />}
      onClick={toggleSelectAllItems}
    >
      {t("translation:common.sellectAll")}
    </Button>
  );
};

export default CheckAll;
