import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import {
  TableContainer,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Modal,
  Typography,
} from "@material-ui/core";
import CheckAll from "../../../../common/components/Actions/CheckAll";
import Send from "../../../../common/components/Actions/Send";
import Delete from "../../../../common/components/Actions/Delete";
import Sort from "../../../../common/components/Actions/Sort";
import CheckedBoldBox from "../../../../common/components/CheckedBoldBox";
import ReceivedTemplatesDetails from "./ReceivedTemplatesDetails";
import {
  getReceivedTemplates,
  getTemplateById,
  removeReceivedTemplate,
} from "../../../../data-services/Templates/TemplatesService";
import { send, shareMany } from "../../../../data-services/common/SendService";
import { IReceivedTemplateModel } from "../../../../models/TemplatesModel";
import SendModal from "../../../../common/components/SendModal";
import DeleteModal from "../../../../common/components/DeleteModal";
import ExportModal from "../../../../common/components/ExportModal";
import { ISendModel, IShareManyModel } from "../../../../models/SendModel";
import StatusModal from "../../../../common/components/StatusModal";
import useStyles from "./ReceivedTemplates.style";
import UncheckedboxSVG from "../../../../common/components/UncheckedBox";
import {
  getDetectorTypeFormatted,
  getDetectorTypeFormattedForFilterMenu,
} from "../../../../data-services/Templates/TemplateMapper";
import { useAuth } from "../../../../context/context";
import { useInterval } from "../../../../context/interval";
import useNothingIsSelectedModalStyles from "../../../../common/styles/nothingIsSelectedModalGeneralStyle";
import NothingSelectedModal from "../../../../common/components/NothingSelectedModal";
import LoadingSpinner from "../../../../LoadingSpinner";

interface IReceivedTemplates {
  filtersData(data: any): void;
  selectedReceivedTemplates: string[];
}

const ReceivedTemplates: React.FC<IReceivedTemplates> = (props) => {
  const classes = useStyles();
  const nothingIsSelectedModalStyles = useNothingIsSelectedModalStyles();
  const cellAlignment = "left";
  const { t } = useTranslation(["translation"]);
  const timeZone = moment.tz.guess();
  const [selectedReceivedTemplatesIds, setSelectedReceivedTemplatesIds] =
    useState<string[]>([]);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [idTemplateToRemove, setIdTemplateToRemove] = useState("");
  const [receivedTemplates, setReceivedTemplates] = useState<
    IReceivedTemplateModel[]
  >([]);
  const [receivedTemplate, setReceivedTemplate] =
    useState<IReceivedTemplateModel>();
  const [sendStatus, setSendStatus] = useState(false);
  const [emailList, setEmailList] = useState([""]);

  const [openSendModal, setOpenSendModal] = useState(false);
  const [openSendNothingIsSelectedModal, setOpenSendNothingIsSelectedModal] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [
    openDeleteNothingIsSelectedModal,
    setOpenDeleteNothingIsSelectedModal,
  ] = useState(false);

  const { filtersData, selectedReceivedTemplates } = props;

  const authStore = useAuth();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    authStore?.getCurrentLanguageCode()
  );
  const [allSelected, setAllSelected] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [dataLeftToFetch, setDataLeftToFetch] = useState(true);
  const [offset, setOffset] = useState(0);
  const [allDataFetched, setAllDataFetched] = useState(false);

  const pageLimit = 100;

  const handleCheck = (
    event: React.ChangeEvent<HTMLInputElement>,
    template: IReceivedTemplateModel
  ) => {
    if (event.target.checked) {
      fetchTemplateById(template.templateSensorId);
      setSelectedReceivedTemplatesIds(
        selectedReceivedTemplatesIds.concat(template.templateSensorId)
      );
    } else {
      setSelectedReceivedTemplatesIds(
        selectedReceivedTemplatesIds.filter(
          (id) => id !== template.templateSensorId
        )
      );
    }
  };

  const receivedTemplatesSortingOptions = [
    { name: t("translation:common.sortByReceivedOn"), key: "receivedDate" },
    { name: t("translation:common.sortByTemplateName"), key: "name" },
    { name: t("translation:common.sortByDetectorType"), key: "detectorType" },
    { name: t("translation:common.sortByReceivedFrom"), key: "sender" },
  ];

  const [sortedBy, setSortedBy] = useState(
    receivedTemplatesSortingOptions[0].key
  );

  const handleChangeSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSortedBy(event.target.value as string);
  };

  const handleOpenDetailsModal = (template: IReceivedTemplateModel) => {
    fetchTemplateById(template.templateSensorId);
    setOpenDetailsModal(true);
    //setReceivedTemplate(template);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
    setReceivedTemplate(undefined);
  };

  const handleOpenDeleteModal = (id: string) => {
    if (selectedReceivedTemplatesIds.length > 0) {
      setIdTemplateToRemove(id);
      setOpenDeleteModal(true);
    } else {
      setOpenDeleteNothingIsSelectedModal(true);
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setOpenDeleteNothingIsSelectedModal(false);
  };

  const handleOpenSendModal = () => {
    if (selectedReceivedTemplatesIds.length > 0 || receivedTemplate) {
      setOpenSendModal(true);
      setOpenDetailsModal(false);
    } else {
      setOpenSendNothingIsSelectedModal(true);
    }
  };

  const handleOpenShareManyModal = () => {
    if (selectedReceivedTemplatesIds.length > 0) {
      setOpenSendModal(true);
      setReceivedTemplate(undefined);
    } else {
      setOpenSendNothingIsSelectedModal(true);
    }
  };

  const handleCloseSendModal = () => {
    setOpenSendModal(false);
    setOpenSendNothingIsSelectedModal(false);
    setReceivedTemplate(undefined);
  };

  const handleOpenExportModal = () => {
    setOpenExportModal(true);
  };

  const handleCloseExportModal = () => {
    setOpenExportModal(false);
    setReceivedTemplate(undefined);
  };

  const handleOpenStatusModal = () => {
    setOpenStatusModal(true);
  };

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
    setEmailList([""]);
    window.location.reload();
  };

  const sortTemplatesByName = (templatesToSort: IReceivedTemplateModel[]) => {
    setSortedBy("name");
    return templatesToSort.sort((a, b) => {
      const templateNameA = !!a.templateName
        ? a.templateName
        : `${a.sensorInformation.name}
          ${t(`translation:BLE_SENSOR_INFORMATION.${a.detectorFamily}`)}
          ${t(`translation:BLE_SENSOR_INFORMATION.${a.detectorType}`)}`;
      const templateNameB = !!b.templateName
        ? b.templateName
        : `${b.sensorInformation.name}
          ${t(`translation:BLE_SENSOR_INFORMATION.${b.detectorFamily}`)}
          ${t(`translation:BLE_SENSOR_INFORMATION.${b.detectorType}`)}`;

      if (templateNameA.toUpperCase() < templateNameB.toUpperCase()) {
        return -1;
      }
      if (templateNameA.toUpperCase() > templateNameB.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  };

  const sortTemplatesByDetectorType = (
    templatesToSort: IReceivedTemplateModel[]
  ) => {
    setSortedBy("detectorType");
    return templatesToSort.sort((a, b) => {
      const detectorTypeA = getDetectorTypeFormatted(a, t);
      const detectorTypeB = getDetectorTypeFormatted(b, t);

      if (detectorTypeA.toUpperCase() < detectorTypeB.toUpperCase()) {
        return -1;
      }
      if (detectorTypeA.toUpperCase() > detectorTypeB.toUpperCase()) {
        return 1;
      }
      if (a.templateName.toUpperCase() < b.templateName.toUpperCase()) {
        return -1;
      }
      if (a.templateName.toUpperCase() > b.templateName.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  };

  const sortTemplatesByDate = (templatesToSort: IReceivedTemplateModel[]) => {
    setSortedBy("receivedDate");
    return templatesToSort.sort((a, b) => {
      if (moment(b.receivedOn).isAfter(a.receivedOn)) {
        return 1;
      }
      return -1;
    });
  };

  const sortTemplatesBySender = (templatesToSort: IReceivedTemplateModel[]) => {
    setSortedBy("sender");
    return templatesToSort.sort((a, b) => {
      if (a.receivedFrom.toUpperCase() < b.receivedFrom.toUpperCase()) {
        return -1;
      }
      if (a.receivedFrom.toUpperCase() > b.receivedFrom.toUpperCase()) {
        return 1;
      }
      if (a.templateName.toUpperCase() < b.templateName.toUpperCase()) {
        return -1;
      }
      if (a.templateName.toUpperCase() > b.templateName.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  };

  const sortReceivedTemplates = (
    key: string,
    dataTemplates: IReceivedTemplateModel[] = []
  ) => {
    let templatesToSort = [];
    if (dataTemplates.length > 0) {
      templatesToSort = dataTemplates;
    } else {
      templatesToSort = [...receivedTemplates];
    }

    setSortedBy(key);

    if (dataLeftToFetch) {
      fetchReceivedTemplates(false, key, 0, pageLimit);
      setOffset(0);
    } else {
      switch (key) {
        case "name":
          setReceivedTemplates(sortTemplatesByName(templatesToSort));
          break;
        case "detectorType":
          setReceivedTemplates(sortTemplatesByDetectorType(templatesToSort));
          break;
        case "receivedDate":
          setReceivedTemplates(sortTemplatesByDate(templatesToSort));
          break;
        case "sender":
          setReceivedTemplates(sortTemplatesBySender(templatesToSort));
          break;
        default:
          setReceivedTemplates(sortTemplatesByDate(templatesToSort));
      }
    }
  };

  const fetchTemplateById = async (templateId: string) => {
    if (
      receivedTemplates[
        receivedTemplates.map((e) => e.templateSensorId).indexOf(templateId)
      ].uploadedAt != null
    ) {
      setReceivedTemplate(
        receivedTemplates[
          receivedTemplates.map((e) => e.templateSensorId).indexOf(templateId)
        ]
      );
      return;
    }
    const { data } = await getTemplateById(
      templateId,
      true,
      authStore?.getImpersonatedUserEmail() || null
    );

    if (data == null) {
      return;
    }
    let receivedTemplate = data as IReceivedTemplateModel;

    receivedTemplates.splice(
      receivedTemplates
        .map((e) => e.templateSensorId)
        .indexOf(receivedTemplate?.templateSensorId),
      1,
      receivedTemplate
    );
    setReceivedTemplates([...receivedTemplates]);
    setReceivedTemplate(receivedTemplate);
  };

  const fetchReceivedTemplates = async (
    withFullInfo: boolean = false,
    sortBy: string = "name",
    offset: number = 0,
    limit: number = 100
  ) => {
    //    if (!dataLeftToFetch && !withFullInfo) return;

    if (allSelected && withFullInfo) {
      return;
    }

    if (withFullInfo) {
      setAllSelected(true);

      setIsFetching(true);
      setDataLeftToFetch(false);
      offset = 0;
      limit = 9999;
      sortBy = "none";
    }

    const { data } = await getReceivedTemplates(
      authStore?.getImpersonatedUserEmail() || null,
      withFullInfo,
      sortBy,
      offset,
      limit
    );
    if (data) {
      let newData: any = [];
      if (offset > 0) {
        newData = [...receivedTemplates, ...data];

        if (sortBy == "detectorType") {
          setReceivedTemplates(sortTemplatesByDetectorType(newData));
        } else {
          setReceivedTemplates(newData);
        }
      } else {
        newData = data;
        if (withFullInfo) {
          // just sort and set templates
          switch (sortBy) {
            case "name":
              setReceivedTemplates(sortTemplatesByName(newData));
              break;
            case "detectorType":
              setReceivedTemplates(sortTemplatesByDetectorType(newData));
              break;
            case "sender":
              setReceivedTemplates(sortTemplatesBySender(newData));
              break;
            default:
              setReceivedTemplates(sortTemplatesByDate(newData));
          }
        } else {
          // The sort by detector type is done on frontend for better performance
          // Avoid an extra JOIN just for the sorting
          if (sortBy == "detectorType") {
            setReceivedTemplates(sortTemplatesByDetectorType(newData));
          } else {
            setReceivedTemplates([...newData]);
          }
        }
      }
      // sortReceivedTemplates(sortedBy, data);

      setIsFetching(false);

      if (withFullInfo) {
        setAllDataFetched(true);
        setSelectedReceivedTemplatesIds(
          newData.map((t: IReceivedTemplateModel) => t.templateSensorId)
        );
      }

      if (data.length < pageLimit) {
        setDataLeftToFetch(false);
      }

      filtersData(newData);
    }
  };

  useInterval(() => {
    if (currentLanguageCode !== authStore?.getCurrentLanguageCode()) {
      setCurrentLanguageCode(authStore?.getCurrentLanguageCode());
      filtersData(receivedTemplates);
    }
  }, 300);

  const handleScroll = (e: any) => {
    if (
      e.target.documentElement.scrollTop + window.innerHeight + 1 >=
      e.target.documentElement.scrollHeight
    ) {
      setIsFetching(true);
      //fetchReceivedTemplates(false, sortedBy, offset, pageLimit);
    }
  };

  useEffect(() => {
    fetchReceivedTemplates(false, sortedBy, 0, 100);
    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!dataLeftToFetch) {
      if (allDataFetched) {
        setIsFetching(false);
        return;
      }
      if (allSelected) return;
      setIsFetching(false);
    }
    if (!isFetching) {
      return;
    }
    if (receivedTemplates.length > 0) {
      setOffset(offset + pageLimit);
      fetchReceivedTemplates(false, sortedBy, offset + pageLimit, pageLimit);
    }
  }, [isFetching]);

  const deleteReceivedTemplate = async (): Promise<any> => {
    
    try {
      const { status } = await removeReceivedTemplate(
        selectedReceivedTemplatesIds,
        authStore?.getImpersonatedUserEmail() || null
      );

      if (status === "success") {
        setReceivedTemplates((prevData) => {
          let copiedTemplates = prevData;
          return [
            ...copiedTemplates.filter(
              (temp) =>
                !selectedReceivedTemplatesIds.includes(temp.templateSensorId)
            ),
          ];
        });
        setSelectedReceivedTemplatesIds([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendReceivedTemplate = async (): Promise<any> => {
    let data: ISendModel | undefined;
    if (receivedTemplate) {
      data = {
        emails: getEmailListWithoutEmptyFields(),
        setting: {
          favoriteName: receivedTemplate.templateName,
          bleSensorInformationId: receivedTemplate.bleSensorInformationId,
          userConfigModel: receivedTemplate.userConfigModel,
          sensorProfile: receivedTemplate.sensorProfile,
          zones: receivedTemplate.zones,
          wiredPushButtonModel: receivedTemplate.wiredPushButtonModel,
          daliPushButtonModel: receivedTemplate.daliPushButtonModel,
          blePushButtonModel: receivedTemplate.blePushButtonModel,
          getSetTunableWhiteListModelReply:
            receivedTemplate.getSetTunableWhiteListModelReply,
          getSetTunableWhitePresetModelReply:
            receivedTemplate.getSetTunableWhitePresetModelReply,
          daliDeviceDataScenesModelReplies:
            receivedTemplate.daliDeviceDataScenesModelReplies,
          getSetWirelessLinkFunctionModelReply:
            receivedTemplate.getSetWirelessLinkFunctionModelReply,
          controlGearDeviceCount: receivedTemplate.controlGearDeviceCount,
          inputDeviceCount: receivedTemplate.inputDeviceCount,
          hvacShortAddressExternalRelay:
            receivedTemplate.hvacShortAddressExternalRelay,
          cutOffShortAddressExternalRelay:
            receivedTemplate.cutOffShortAddressExternalRelay,
          daliBusDevices: receivedTemplate.daliBusDevices,
        },
      };
    }
    handleCloseSendModal();
    handleCloseDetailsModal();

    try {
      if (data) {
        const { status } = await send(data);
        handleOpenStatusModal();
        status === "success" ? setSendStatus(true) : setSendStatus(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const shareManyReceivedTemplates = async (): Promise<any> => {
    let data: IShareManyModel | undefined;

    if (receivedTemplates.length > 0) {
      data = {
        emails: getEmailListWithoutEmptyFields(),
        settings: receivedTemplates
          .filter((receivedTemplate) =>
            selectedReceivedTemplatesIds.includes(
              receivedTemplate.templateSensorId
            )
          )
          .map((rTemplate) => ({
            favoriteName: rTemplate.templateName,
            bleSensorInformationId: rTemplate.bleSensorInformationId,
            userConfigModel: rTemplate.userConfigModel,
            sensorProfile: rTemplate.sensorProfile,
            zones: rTemplate.zones,
            wiredPushButtonModel: rTemplate.wiredPushButtonModel,
            daliPushButtonModel: rTemplate.daliPushButtonModel,
            blePushButtonModel: rTemplate.blePushButtonModel,
            getSetTunableWhiteListModelReply:
              rTemplate.getSetTunableWhiteListModelReply,
            getSetTunableWhitePresetModelReply:
              rTemplate.getSetTunableWhitePresetModelReply,
            daliDeviceDataScenesModelReplies:
              rTemplate.daliDeviceDataScenesModelReplies,
            getSetWirelessLinkFunctionModelReply:
              rTemplate.getSetWirelessLinkFunctionModelReply,
            controlGearDeviceCount: rTemplate.controlGearDeviceCount,
            inputDeviceCount: rTemplate.inputDeviceCount,
            hvacShortAddressExternalRelay:
              rTemplate.hvacShortAddressExternalRelay,
            cutOffShortAddressExternalRelay:
              rTemplate.cutOffShortAddressExternalRelay,
            daliBusDevices: rTemplate.daliBusDevices,
          })),
      };
    }
    handleCloseSendModal();
    handleCloseDetailsModal();

    try {
      if (data) {
        const { status } = await shareMany(data);
        handleOpenStatusModal();
        status === "success" ? setSendStatus(true) : setSendStatus(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEmailListWithoutEmptyFields = () => {
    let res = [];
    for (let i = 0; i < emailList.length; ++i) {
      if (emailList[i].length !== 0) {
        res.push(emailList[i]);
      }
    }
    return res;
  };

  const getTemplatedIdsFromSelectedFilters = () => {
    return receivedTemplates
      .filter((rTemplates) => {
        if (selectedReceivedTemplates.length === 0) {
          return true;
        }
        return selectedReceivedTemplates.includes(
          getDetectorTypeFormattedForFilterMenu(rTemplates, t)
        );
      })
      .map((template: IReceivedTemplateModel) => {
        return template.templateSensorId;
      });
  };

  const getStatusContent = () => {
    if (sendStatus) {
      return (
        <>
          <Grid item>
            <Typography className={classes.modalParagraph}>
              {t("translation:Templates_Receivedtemplates.successContent")}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.modalParagraph}>
              {t("translation:Templates_Receivedtemplates.successContentInfo")}
            </Typography>
          </Grid>
        </>
      );
    } else {
      return (
        <Grid item>
          <Typography className={classes.modalParagraph}>
            {t("translation:Templates_Receivedtemplates.failedContent").replace(
              "::TEMPLATE_NAME::",
              receivedTemplate ? receivedTemplate.templateName : ""
            )}
          </Typography>
        </Grid>
      );
    }
  };

  return (
    <>
      <Modal open={isFetching}>
        <div style={{ top: "50%" }}>{isFetching && <LoadingSpinner />}</div>
      </Modal>
      <Modal
        open={openSendModal ? openSendModal : openSendNothingIsSelectedModal}
        onClose={handleCloseSendModal}
      >
        <Grid
          container
          direction="column"
          className={
            openSendModal
              ? classes.modalSendCard
              : nothingIsSelectedModalStyles.style
          }
        >
          {openSendModal && (
            <SendModal
              title={t("translation:Templates_Mytemplates.sendModal.title")}
              subTitle={t(
                "translation:Templates_Mytemplates.sendModal.subTitle"
              )}
              content={t("translation:Templates_Mytemplates.sendModal.content")}
              send={
                receivedTemplate
                  ? sendReceivedTemplate
                  : shareManyReceivedTemplates
              }
              emails={emailList}
              setEmails={setEmailList}
              closeModal={handleCloseSendModal}
            />
          )}
          {openSendNothingIsSelectedModal && (
            <NothingSelectedModal
              title={t(
                "translation:Projects_Myprojects.deleteProjects.titleNothingIsSelected"
              )}
              content={t(
                "translation:Templates_Mytemplates.sendModal.nothingIsSelected"
              )}
              closeModal={handleCloseSendModal}
            />
          )}
        </Grid>
      </Modal>
      <Modal open={openStatusModal} onClose={handleCloseStatusModal}>
        <Grid container direction="column" className={classes.modalStatusCard}>
          <StatusModal
            retry={false}
            retryMethod={
              receivedTemplate
                ? sendReceivedTemplate
                : shareManyReceivedTemplates
            }
            title={
              sendStatus
                ? t(
                    "translation:Templates_Receivedtemplates.sendStatus.successTitle"
                  )
                : t(
                    "translation:Templates_Receivedtemplates.sendStatus.failedTitle"
                  )
            }
            content={getStatusContent}
            closeModal={handleCloseStatusModal}
          />
        </Grid>
      </Modal>
      <Modal
        open={
          selectedReceivedTemplatesIds.length > 0
            ? openDeleteModal
            : openDeleteNothingIsSelectedModal
        }
        onClose={handleCloseDeleteModal}
      >
        <Grid
          container
          direction="column"
          className={
            openDeleteModal
              ? classes.modalDeleteCard
              : nothingIsSelectedModalStyles.style
          }
        >
          {openDeleteModal && (
            <DeleteModal
              title={t("translation:common.deleteModal.title")}
              content={t("translation:common.deleteModal.content")}
              remove={() => {
                deleteReceivedTemplate();
                handleCloseDeleteModal();
              }}
              deleteButtonName={t("translation:common.delete")}
              closeModal={handleCloseDeleteModal}
            />
          )}
          {openDeleteNothingIsSelectedModal && (
            <NothingSelectedModal
              title={t(
                "translation:Projects_Myprojects.deleteProjects.titleNothingIsSelected"
              )}
              content={t(
                "translation:Templates_Receivedtemplates.nothingIsSelected"
              )}
              closeModal={handleCloseDeleteModal}
            />
          )}
        </Grid>
      </Modal>
      <Modal open={openDetailsModal} onClose={handleCloseDetailsModal}>
        <Grid
          container
          direction="column"
          className={classes.detailsModalContainer}
        >
          {receivedTemplate && (
            <ReceivedTemplatesDetails
              receivedTemplate={receivedTemplate}
              closeModal={() => handleCloseDetailsModal()}
              openSendModal={handleOpenSendModal}
            />
          )}
        </Grid>
      </Modal>
      <Modal open={openExportModal} onClose={handleCloseExportModal}>
        <Grid container direction="column" className={classes.modalExportCard}>
          <ExportModal
            title={t("translation:exportModal.templateTitle")}
            closeModal={handleCloseExportModal}
          />
        </Grid>
      </Modal>
      <Grid container className={classes.actionsBar}>
        <Grid item className={classes.actionItems}>
          <CheckAll
            allItemIds={getTemplatedIdsFromSelectedFilters()}
            selectedItemIds={selectedReceivedTemplatesIds}
            setSelectedItemsIds={setSelectedReceivedTemplatesIds}
            fetchData={() => fetchReceivedTemplates(true)}
          />
          <Send
            openSendModal={handleOpenShareManyModal}
            closeModal={handleCloseSendModal}
          />
          <Delete
            openDeleteModal={() => handleOpenDeleteModal(idTemplateToRemove)}
          />
        </Grid>

        <Sort
          sortItems={sortReceivedTemplates}
          sortedBy={sortedBy}
          sortingOptions={receivedTemplatesSortingOptions}
          handleChangeSelect={handleChangeSelect}
        />
      </Grid>
      <TableContainer className={classes.receivedTemplatesContainer}>
        <Table className={classes.table} aria-label="simple table">
          <colgroup>
            <col style={{ width: "5%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "15%" }} />
          </colgroup>
          <TableHead className={classes.headingRow}>
            <TableRow>
              <TableCell className={classes.columnName}></TableCell>
              <TableCell className={classes.columnName}>
                {t(
                  "translation:Templates_Receivedtemplates.tableHeadings.templateName"
                )}
              </TableCell>
              <TableCell className={classes.columnName} align={cellAlignment}>
                {t(
                  "translation:Templates_Receivedtemplates.tableHeadings.detectorType"
                )}
              </TableCell>
              <TableCell className={classes.columnName} align={cellAlignment}>
                {t(
                  "translation:Templates_Receivedtemplates.tableHeadings.receivedOn"
                )}
              </TableCell>
              <TableCell className={classes.columnName} align={"right"}>
                {t(
                  "translation:Templates_Receivedtemplates.tableHeadings.receivedFrom"
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receivedTemplates
              .filter((rTemplates) => {
                if (selectedReceivedTemplates.length === 0) {
                  return true;
                }
                return selectedReceivedTemplates.includes(
                  getDetectorTypeFormattedForFilterMenu(rTemplates, t)
                );
              })
              .map((rt) => (
                <TableRow key={rt.templateSensorId}>
                  <TableCell align={cellAlignment} className={classes.check}>
                    <Checkbox
                      checked={selectedReceivedTemplatesIds.some(
                        (id) => id === rt.templateSensorId
                      )}
                      onChange={(event) => handleCheck(event, rt)}
                      checkedIcon={<CheckedBoldBox />}
                      inputProps={{ "aria-labelledby": "checkbox" }}
                      icon={<UncheckedboxSVG />}
                      disableRipple
                    />
                  </TableCell>
                  <TableCell
                    align={cellAlignment}
                    className={classes.templateName}
                    onClick={() => handleOpenDetailsModal(rt)}
                  >
                    {!!rt.templateName
                      ? rt.templateName
                      : `${rt.sensorInformation.name}
                        ${t(
                          `translation:BLE_SENSOR_INFORMATION.${rt.sensorInformation.detectorFamily}`
                        )}
                        ${t(
                          `translation:BLE_SENSOR_INFORMATION.${rt.detectorType}`
                        )}`}
                  </TableCell>
                  <TableCell className={classes.content} align={cellAlignment}     onClick={() => handleOpenDetailsModal(rt)}>
                    {getDetectorTypeFormatted(rt, t)}
                  </TableCell>
                  <TableCell className={classes.content} align={cellAlignment}     onClick={() => handleOpenDetailsModal(rt)}>
                    {moment.tz(rt.receivedOn, timeZone).format("DD.MM.YYYY")}
                  </TableCell>
                  <TableCell className={classes.content} align={"right"}     onClick={() => handleOpenDetailsModal(rt)}>
                    {rt.receivedFrom}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReceivedTemplates;
