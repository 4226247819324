import { ILanguage } from "./base-language";

export const sz_DE: ILanguage = {
  /** Routes */
  home: "/home",
  about: "/about",
  projectsMy: "/projects/my",
  detector: "/detector",
  templates: "/templates",
  register: "/register",
  registerConfirmation: "/register/confirmation",
  accountActivated: "/register/accountActivated",
  activationFailed: "/register/activationFailed",
  activationLinkExpired: "/activationLinkExpired",
  404: "/404",
  changeEmailConfirmation: "/changeEmailConfirmation",
  myAccount: "/myAccount",
  forgotPassword: "/login/forgotPassword",
  resetEmailConfirmation: "/login/resetEmailConfirmation",
  resetPassword: "/login/resetPassword/:token",
  changeEmail: "/changeEmail",
  installerView: "/installerView",
  installerViewLoggedIn: "/installerViewLoggedIn",
  project: "/project/:id",

  customerServicesLink: "https://www.niko.eu/de-ch/hilfe-benotigt/kundendienst",
  termsAndConditionsLink: "https://www.niko.eu/de-ch/legal",
  privacyPolicyLink: "https://www.niko.eu/de-ch/legal/privacy-policy"
};
