import { makeStyles, createStyles } from "@material-ui/core/styles";
import colors from "../../../../common/styles/colors";
import { commonParagraph14px } from "../../../../common/styles/typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    infoItem: {},
    nestedInfo: {
      "& span": {
        color: colors.primaryColor,
        ...commonParagraph14px,
      },
    },
    boldText: {
      "& span": {
        color: colors.primaryColor,
        fontWeight: 700,
        ...commonParagraph14px,
      },
    },
    greyedOutText: {
      "& span": {
          color: colors.lightGreyColor,
          ...commonParagraph14px,
      },
    },
    checkbox: {
      minWidth: "20px",
      width: "20px",
      height: "20px",
      marginRight: "16px",
      "& .MuiCheckbox-colorSecondary.Mui-checked:hover": {
        backgroundColor: "transparent",
      },
      "& .MuiIconButton-root:hover": {
        backgroundColor: "transparent",
      },
      "& span": {
        padding: 0,
        cursor: 'pointer',

      },
    },
  })
);

export default useStyles;
