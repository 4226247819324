import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import CreateNewAccount from "./components/NewAccount/CreateNewAccount";
import MyAccount from "./components/MyAccount/MyAccount";
import HomeLogedIn from "./components/Home/HomeLogedIn";
import About from "./components/AboutTool/About";
import ProjectsList from "./components/Projects/ProjectsList";
import Project from "./components/Projects/Project";
import Detector from "./components/Detector/Detector";
import Template from "./components/Templates/Templates";
import ForgotPassword from "./components/Auth/ResetPassword/ForgotPassword";
import ResetPassword from "./components/Auth/ResetPassword/ResetPassword";
import { CssBaseline } from "@material-ui/core";
import {
  LocalizedRouter,
  LocalizedSwitch,
  appStrings,
} from "./common/services/i18n";
import { AppLanguage } from "./common/enums/laguages";
import { AppRoute } from "./common/constants/routes";
import { AuthContext, useAuth } from "./context/context";
import PrivateRoute from "./components/Auth/PrivateRoute";
import LoggedOutRoute from "./components/Auth/LoggedOutRoute";
import { IUser, IUserHistory, IUserUpdate } from "./models/User";
import RegisterStatus from "./components/NewAccount/RegisterStatus";
import AccountActivated from "./components/NewAccount/AccountActivated";
import ActivationFailed from "./components/NewAccount/ActivationFailed";
import Page404 from "./components/Errors/Page404";
import ActivationLinkExpired from "./components/NewAccount/ActivationLinkExpired";
import EmailSentConfirmation from "./components/Auth/ResetPassword/EmailSentConfirmation";
import useStyles from "./App.style";
import "./App.style.ts";
import {
  getNewMyTemplatesCount,
  getReceivedMyTemplatesCount,
} from "./data-services/Templates/TemplatesService";
import ChangeEmailStatus from "./components/MyAccount/ChangeEmailStatus";
import InstallerView from "./components/InstallerView/InstallerView";
import InstallerViewLoggedIn from "./components/InstallerView/InstallerViewLoggedIn";
import ChangeEmailConfirmation from "./components/MyAccount/ChangeEmailConfirmation";
import { useHistory } from "react-router-dom";
import ErrorHandler from "./data-services/ErrorHandler";

const App: React.FC = () => {
  const classes = useStyles();
  const authStore = useAuth();
  const history = useHistory();

  const currentPath = window.location.pathname;
  let currentLanguageCode = currentPath.split("/")[1];
  var keyss = Object.values(AppLanguage);

  // Set corrent language code if inserted or typed wrong in the url
  if (!currentLanguageCode || !keyss.includes(currentLanguageCode)) {
    let matchingCode = "en";
    if (currentLanguageCode) {
      let lowerCaseCurrentLangCode = currentLanguageCode.toLowerCase();

      keyss.forEach((k) => {
        if (k.toLowerCase() == lowerCaseCurrentLangCode) {
          matchingCode = k;
        }
      });
    }
    currentLanguageCode = matchingCode;
  }
  localStorage.setItem("currentLanguageCode", currentLanguageCode);

  const localToken = () => localStorage.getItem("tokens") || null;

  const [authTokens, setAuthTokens] = useState<string | null>(null);
  const [isLogged, setIsLogged] = useState(localToken() !== null);
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [userHistory, setUserHistory] = useState<IUserHistory>({
    lastName: "",
    firstName: "",
    name: "",
    email: "",
    country: "",
    language: "",
    isDetectorNotificationSeen: false,
    lastReceivedTemplatesSeen: 0,
    lastMyTemplatesSeen: 0,
  });
  const [newReceivedTemplates, setNewReceivedTemplates] = useState(0);
  const [myNewTemplates, setMyNewTemplates] = useState(0);

  let globalAccess = localStorage.getItem("globalAccess") ?? "";
  const [hasAccessToPlatform] = useState(globalAccess === "true");

  const toImpersonateUserEmail = localStorage.getItem("toImpersonateUserEmail");
  const impersonatedUserEmail = localStorage.getItem("impersonatedUserEmail");

  const countMyNewTemplates = async () => {
    if (userHistory?.lastMyTemplatesSeen == 0) {
      return;
    }

    const { data } = await getNewMyTemplatesCount(
      userHistory?.lastMyTemplatesSeen,
      getImpersonatedUserEmail()
    );

    if (data != null) {
      setMyNewTemplates(data);
    }
  };

  const countReceivedTemplates = async () => {
    // if (userHistory?.lastReceivedTemplatesSeen == 0) {
    //   return;
    // }

    // const { data } = await getReceivedMyTemplatesCount(
    //   userHistory?.lastReceivedTemplatesSeen,
    //   getImpersonatedUserEmail()
    // );

    // if (data != null) {
    //   setNewReceivedTemplates(data);
    // }
  };

  // REMOVED TO NOW SHOW THE INDICATOR WHEN NEW TEMPLATE HAS ARRIVED OR CHANGED IN NAME OR SIMILIAR. sAME FOR RECIVED TEMPLATES
  // useEffect(() => {
  //   countReceivedTemplates();
  // }, [userHistory?.lastReceivedTemplatesSeen]);

  // useEffect(() => {
  //   countMyNewTemplates();
  // }, [userHistory?.lastMyTemplatesSeen]);

  useEffect(() => {
    if (authStore?.isLoggedIn) return;
    if (localToken() !== null) {
      setIsLogged(true);
    }
  }, [authStore]);

  const handleUserHistory = () => {
    setUserHistory(getUserHistory());
  };

  const setTokens = (token: string) => {
    localStorage.setItem("tokens", token);
    setAuthTokens(token);
  };

  const persistUser = (user: IUser) => {
    if (user.id) {
      const userHistory: IUserHistory = {
        firstName: user.firstName,
        lastName: user.lastName,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        country: user.country,
        language: user.language,
        lastReceivedTemplatesSeen: 0,
        lastMyTemplatesSeen: 0,
        isDetectorNotificationSeen: false,
      };
      const usersHistory = {
        [user.id]: userHistory,
      };
      localStorage.setItem("userHistory", JSON.stringify(usersHistory));
      setIsSuperUser(user.isSuperUser);
    }
  };

  const updateUser = (updateData: IUserUpdate) => {
    let userHistory = getUserHistory();
    if (userHistory === undefined || userHistory === null) {
      return;
    }

    userHistory.firstName = updateData.firstName;
    userHistory.lastName = updateData.lastName;
    userHistory.name = userHistory.firstName + " " + userHistory.lastName;
    userHistory.emailAddress = updateData.emailAddress;
    userHistory.country = updateData.country;
    userHistory.language = updateData.language;

    let currentUserId = localStorage.getItem("currentUserId");
    if (currentUserId) {
      let obToSave: { [key: string]: string } = {};
      obToSave[currentUserId] = userHistory;
      localStorage.setItem("userHistory", JSON.stringify(obToSave));
      userHistory = getUserHistory();
      handleUserHistory();
    }
  };

  const updateCurrentLanguageCode = (languageCode: string) => {
    localStorage.setItem("currentLanguageCode", languageCode);
  };

  const getCurrentLanguageCode = (): string => {
    const currentLanguageCode = localStorage.getItem("currentLanguageCode");
    return currentLanguageCode || "en";
  };

  const setCurrentUserId = (id: string) => {
    localStorage.setItem("currentUserId", id);
    handleUserHistory();
  };

  const setIsSuperUser = (isSuperUser: boolean) => {
    localStorage.setItem("isSuperUser", isSuperUser.toString());
  };

  const getIsSuperUser = (): boolean => {
    return localStorage.getItem("isSuperUser") === "true" || false;
  };

  const setImpersonatedUserEmail = (impersonatedUserEmail: string) => {
    localStorage.setItem("impersonatedUserEmail", impersonatedUserEmail);
  };

  const getImpersonatedUserEmail = (): string | null => {
    const email = localStorage.getItem("impersonatedUserEmail");
    if (email === null || email === "null") {
      return null;
    }
    return email;
  };

  const getToImpersonateUserEmail = (): string | null => {
    const email = localStorage.getItem("toImpersonateUserEmail");
    if (email === null || email === "null") {
      return null;
    }
    return email;
  };

  const setImpersonatedUserHistory = (
    impersonatedUserHistory: string | null
  ) => {
    localStorage.setItem(
      "userImpersonatedHistory",
      JSON.stringify(impersonatedUserHistory)
    );
  };

  const getImpersonatedUserHistory = () => {
    const userData = localStorage.getItem("userImpersonatedHistory");
    if (userData === null) {
      return null;
    }
    return JSON.parse(userData);
  };

  const getUserHistory = () => {
    const userData = localStorage.getItem("userHistory");
    const userId = localStorage.getItem("currentUserId");
    if (userData && userId) {
      const objData = JSON.parse(userData);
      return objData[userId];
    }
  };

  const updateUserHistory = (user: IUserHistory) => {
    const userData = localStorage.getItem("userHistory");
    const userId = localStorage.getItem("currentUserId");
    if (userData && userId) {
      const objData = JSON.parse(userData);
      objData[userId].isDetectorNotificationSeen =
        user.isDetectorNotificationSeen;
      objData[userId].lastReceivedTemplatesSeen =
        user.lastReceivedTemplatesSeen;
      objData[userId].lastMyTemplatesSeen = user.lastMyTemplatesSeen;
      localStorage.setItem("userHistory", JSON.stringify(objData));
      setUserHistory(objData[userId]);
    }
  };

  const removeItems = () => {
    localStorage.removeItem("tokens");
    localStorage.removeItem("currentUserId");
  };

  const onFocusFunction = () => {
    return;
    let isLoggedInStorage = localToken() !== null;

    if (!isLoggedInStorage && isLogged) {
      window.location.reload();
    } else if (
      impersonatedUserEmail !== localStorage.getItem("impersonatedUserEmail")
    ) {
      window.location.reload();
    } else if (
      toImpersonateUserEmail !== localStorage.getItem("toImpersonateUserEmail")
    ) {
      window.location.reload();
    }

    setIsLogged(isLoggedInStorage);
    handleUserHistory();
  };

  const forceDeauthentication = async () => {
    localStorage.clear();
    setIsLogged(false);
  };

  useEffect(() => {
    handleUserHistory();

    window.addEventListener("focus", onFocusFunction);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        authTokens,
        userHistory: userHistory,
        updateUserHistory: updateUserHistory,
        updateUser: updateUser,
        setAuthTokens: setTokens,
        isLoggedIn: isLogged,
        setIsLogged,
        removeItems,
        updateCurrentLanguageCode,
        getCurrentLanguageCode,
        setCurrentUserId,
        persistUser,
        setIsSuperUser,
        getIsSuperUser,
        setImpersonatedUserEmail,
        getImpersonatedUserEmail,
        setImpersonatedUserHistory,
        getImpersonatedUserHistory,
        newReceivedTemplates: newReceivedTemplates,
        countReceivedTemplates,
        myNewTemplates,
        countMyNewTemplates,
        forceDeauthentication,
      }}
    >
      <ErrorHandler>
        <LocalizedRouter
          RouterComponent={BrowserRouter}
          languages={AppLanguage}
          appStrings={appStrings}
        >
          <CssBaseline />
          <Header />
          <main className={classes.mainContent}>
            <LocalizedSwitch>
              <Route
                path={AppRoute.Home}
                render={() => {
                  if (isLogged) {
                    if (getIsSuperUser()) {
                      if (getToImpersonateUserEmail() === null) {
                        return <InstallerView />;
                      } else {
                        return <InstallerViewLoggedIn />;
                      }
                    } else {
                      return <HomeLogedIn />;
                    }
                  }
                  return <Home />;
                }}
              />
              <Route
                path={AppRoute.RegisterConfirmation}
                component={RegisterStatus}
              />
              <Route
                path={AppRoute.AccountActivated}
                component={AccountActivated}
              />
              <Route
                path={AppRoute.ActivationFailed}
                component={ActivationFailed}
              />
              <Route
                path={AppRoute.ActivationLinkExpired}
                component={ActivationLinkExpired}
              />
              <Route
                path={AppRoute.ChangeEmailConfirmation}
                component={ChangeEmailConfirmation}
              />
              <Route
                path={AppRoute.ResetEmailConfirmation}
                component={EmailSentConfirmation}
              />
              <Route
                path={AppRoute.ChangeEmailStatus}
                component={ChangeEmailStatus}
              />
              <Route path={AppRoute.About} component={About} />
              <LoggedOutRoute
                path={AppRoute.Register}
                component={CreateNewAccount}
              />
              <LoggedOutRoute
                path={AppRoute.ResetPassword}
                component={ResetPassword}
              />
              <LoggedOutRoute
                path={AppRoute.ForgotPassword}
                component={ForgotPassword}
              />
              <PrivateRoute path={AppRoute.MyAccount} component={MyAccount} />
              <PrivateRoute
                path={AppRoute.ProjectsMy}
                component={ProjectsList}
              />
              <PrivateRoute path={AppRoute.Project} component={Project} />
              <PrivateRoute path={AppRoute.Detector} component={Detector} />
              <PrivateRoute path={AppRoute.Templates} component={Template} />
              {/* All 404 or non existing paths should redirect to Home*/}
              {/* <Route path={AppRoute.Page404} component={Page404} /> */}
              <Redirect from="*" to={AppRoute.Home} />
              {getIsSuperUser() ? (
                <Route
                  path={AppRoute.InstallerView}
                  component={InstallerView}
                />
              ) : (
                <Redirect to={AppRoute.Home} />
              )}
              {getIsSuperUser() ? (
                <Route
                  path={AppRoute.InstallerViewLoggedIn}
                  component={InstallerViewLoggedIn}
                />
              ) : (
                <Redirect to={AppRoute.Home} />
              )}
            </LocalizedSwitch>
          </main>

          <Footer />
        </LocalizedRouter>
      </ErrorHandler>
    </AuthContext.Provider>
  );
};

export default App;
