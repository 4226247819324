import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useAuth } from "../../../context/context";
import {
  Grid,
  Typography,
  TextField,
  InputLabel,
  InputAdornment,
  IconButton,
  Button,
  Box,
} from "@material-ui/core";
import { getLocalizedRoute } from "../../../common/services/i18n/utils/routeLocalization";
import { access, login } from "../../../data-services/Auth/AuthService";
import { AppRoute } from "../../../common/constants/routes";
import useStyles from "./Login.style";
import useButtonStyles from "../../../common/styles/buttonStyles";
import { languageList } from "../../../common/constants/countries";
import { hasThisCountryWithThisLanguageSpecialLocaleCode } from "../../../common/enums/laguages"
import i18n from "../../../i18n";
import { IResendRegisterModel } from "../../../models/AuthModel";
import { resendRegisterEmail } from "../../../data-services/Auth/AuthService";
import ShowPassword from "../../../common/components/ShowPassword";

interface ILogin {
  maintenancePage: boolean;
}

const Login: React.FC<ILogin> = (props) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const { t } = useTranslation(["translation"]);
  const { formatMessage, locale } = useIntl();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAccountActivated, setIsAccountActivated] = useState(true);
  const [isloginDataValid, setIsLoginDataValid] = useState<
    boolean | undefined
  >();
  const authStore = useAuth();
  let history = useHistory();
  const { maintenancePage } = props;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const setAccessToPlatformLocalStorage = (value: boolean) => {
    localStorage.setItem("globalAccess", value.toString());
  }

  const postLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (authStore) {
      if (!maintenancePage) {
        const { data: user, status, statusCode, message } = await login({ email, password });
        if (status === "success") {
          const language = languageList.find((l) => l.languageId === user.language);
          const country = user.country;
          if (language?.localeCode) {
            let localeCodeToSet = hasThisCountryWithThisLanguageSpecialLocaleCode(country, language?.name);
            if (localeCodeToSet === undefined) {
              localeCodeToSet = language?.localeCode;
            }
            i18n.changeLanguage(localeCodeToSet);
            authStore.updateCurrentLanguageCode(localeCodeToSet);
            history.push(getLocalizedRoute(AppRoute.Home, localeCodeToSet, formatMessage));
            //window.location.reload();
          }

          authStore.setAuthTokens(user.jwt);
          authStore.setIsLogged(true);
          authStore.setUser(user);
          authStore.persistUser(user);
          authStore.setCurrentUserId(user.id);
          // authStore.countReceivedTemplates();
          authStore.setIsSuperUser(user.isSuperUser);
          authStore.setImpersonatedUserEmail(user.impersonatedUserEmail);
          // authStore.countMyNewTemplates();

          setIsLoginDataValid(true);
        } else {
          if (statusCode == 400 && message == "Account is not activated") {
            setIsAccountActivated(false);
          }
          else {
            setIsAccountActivated(true);
          }

          authStore.setAuthTokens();
          authStore.removeItems();
          authStore.setIsLogged(false);
          setIsLoginDataValid(false);
        }
      } else {
        const { status } = await access(password, i18n.language ?? i18n.languages[0]);
        if (status === "success") {
          setAccessToPlatformLocalStorage(true);
          history.push(getLocalizedRoute(AppRoute.Home, locale, formatMessage));
          window.location.reload();
        }
      }
    }
  };

  const redirectToRegister = () => {
    history.push(getLocalizedRoute(AppRoute.Register, locale, formatMessage));
  };

  const isLoginFormValid = () => {
    if (isloginDataValid === undefined) {
      return true;
    }
    return isloginDataValid;
  };

  const resendEmail = async () => {
    try {
      const resendData: IResendRegisterModel = {
        email: email,
        browserLanguage: i18n.language
      };

      await resendRegisterEmail(resendData);

      history.push(`${getLocalizedRoute(AppRoute.RegisterConfirmation, locale, formatMessage)}?email=${encodeURIComponent(email)}`);
    } catch (error) {
      return { status: "error", message: "Error" };
    }
  };

  return (
    <Grid
      container
      component="form"
      direction="column"
      onSubmit={postLogin}
      className={classes.formContainer}
    >
      <Grid item>
        <Typography component="h2" className={classes.mainTitle}>
          {maintenancePage ? t("translation:home.mainTitleMaintenance") : t("translation:home.mainTitle")}
        </Typography>
      </Grid>

      {
        !maintenancePage
        &&
        <Grid item>
          <div
            className={`${classes.verticalInputSpacing} ${!isLoginFormValid() && classes.errorBorder
              } `}
          >
            <InputLabel className={classes.inputLabel}>
              {t("translation:common.emailLabel")}
            </InputLabel>
            <TextField
              type="email"
              variant="outlined"
              fullWidth={true}
              focused={false}
              className={classes.textInput}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </Grid>
      }

      <Grid item>
        <div
          className={`${classes.lastTextfield} ${!isLoginFormValid() && classes.errorBorder}`}
        >
          <div className={classes.labelPassword}>
            <InputLabel className={classes.inputLabel}>
              {t("translation:common.passwordLabel")}
            </InputLabel>
            <Link
              to={getLocalizedRoute(
                AppRoute.ForgotPassword,
                locale,
                formatMessage
              )}
              className={classes.inputLabel}
              style={{textDecorationSkipInk: "none"}}
            >
              {
                !maintenancePage
                &&
                t("translation:common.forgotPasswordLink")
              }
            </Link>
          </div>

          {
            !maintenancePage
            &&
            <TextField
              type={showPassword ? "text" : "password"}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <IconButton onClick={handleClickShowPassword}>
                      <ShowPassword showPassword={showPassword} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
              className={classes.textInput}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          }

          {
            maintenancePage
            &&
            <TextField
              type={"password"}
              variant="outlined"
              fullWidth={true}
              className={classes.textInput}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          }

          {!isLoginFormValid() && (
            <Box>
              {isAccountActivated &&
                <Typography style={{ float: 'left' }} className={classes.error}>
                  {t("translation:common.emailorPasswordError")}
                </Typography>
              }

              {!isAccountActivated &&
                <div>
                  <Typography style={{ float: 'left' }} className={classes.error}>
                    {t("translation:common.accountNotActivated")}
                  </Typography>

                  <Typography style={{ float: 'right', textDecorationLine: 'underline', cursor: 'pointer' }} className={classes.error} onClick={resendEmail}>
                    {t("translation:common.resendActivationMail")}
                  </Typography>
                </div>
              }
            </Box>
          )}
        </div>
      </Grid>

        {
          !maintenancePage
          &&
          <Grid item className={classes.verticalSpacing}>
            <Button
              onClick={redirectToRegister}
              className={buttonClasses.lightButton}
              fullWidth={true}
            >
              {t("translation:common.newUserButton")}
            </Button>
          </Grid>
        }

      <Grid item>
        <Button
          type="submit"
          className={buttonClasses.primaryButton}
          fullWidth={true}
          disabled={!email || !password}
        >
          {t("translation:common.loginButton")}
        </Button>
      </Grid>


      {
        maintenancePage
        &&
        <p style={{ marginTop: "10px" }}>{t("translation:home.underConstruction")}</p>
      }

    </Grid>
  );
};

export default Login;
