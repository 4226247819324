import { IDetectorModel } from "../../../models/DetectorModel";
import { IProject } from "../../../models/Project";
import { ISensorSetting } from "../../../models/SensorSetting";
import { IReceivedTemplateModel, ITemplateModel } from "../../../models/TemplatesModel";

import { getDetectorTypeFormatted } from "./getDetectorTypeFormatted";
import GetDefaultDescription from './GetDefaultDescription';
import i18n from "i18next";
import { jsPDF } from "jspdf";
import logoSrc from './logo.png';
import { HelveticaFont } from "./Helvetica-Font";
import { HelveticaFontBold } from "./Helvetica-Font-Bold";
import { sortLightSceneGroupsByPercentage } from "./ExportLightSceneHelper";
import moment from "moment";

let exportType = 'detectors';

function isExportingDetectors() {
  return exportType === 'detectors' ? true : false;
}

function isExportingProjects() {
  return exportType === 'projects' ? true : false;
}

function getTextLines(doc: any, text: string, textMaxLength: number) {
  let textLines = doc.splitTextToSize(text, textMaxLength);
  return textLines.length;
}

function heightToAdd(doc: any, text: string, textMaxLength: number, heightUnit: number = 6.5) {
  let textLines = getTextLines(doc, text, textMaxLength);
  if (textLines <= 1) {
    return 0;
  }
  return (textLines - 1) * heightUnit;
}

function convertPixelsToMilimeters(pixels: number) {
  // 595 width to 794 => ratio = 1.334453781512605
  pixels = pixels * 1.334453781512605;

  return pixels / 3.7795275591;
}

const returnMacFormatted = (mac: any) => {
  return mac?.split("").map((num: any, i: number) => (i % 2 === 0 && i !== 0) ? `:${num}` : num).join("");
}

const addHeaderFooter = (doc: any, forToc: boolean = false): void => {
  doc.setFontSize(10);
  doc.setTextColor(54, 54, 54);
  doc.setFont("Helvetica-TTF", "normal");
  doc.setLineWidth(0.1);

  //Header
  let logo = new Image();
  logo.src = logoSrc;

  doc.addImage(logo, 'png', convertPixelsToMilimeters(38), convertPixelsToMilimeters(22), convertPixelsToMilimeters(80), convertPixelsToMilimeters(30.5));
  doc.setDrawColor("#000000");
  doc.line(convertPixelsToMilimeters(126), convertPixelsToMilimeters(26), convertPixelsToMilimeters(126), convertPixelsToMilimeters(52));
  doc.setLineWidth(0.1);

  if (forToc) {
    doc.line(convertPixelsToMilimeters(32),
      convertPixelsToMilimeters(64),
      doc.getPageWidth() - convertPixelsToMilimeters(32),
      convertPixelsToMilimeters(64));
  }
  else {
    doc.line(convertPixelsToMilimeters(16),
      convertPixelsToMilimeters(90),
      doc.getPageWidth() - convertPixelsToMilimeters(16),
      convertPixelsToMilimeters(90));
  }
  //Footer
  doc.line(convertPixelsToMilimeters(32),
    doc.getPageHeight() - convertPixelsToMilimeters(64),
    doc.getPageWidth() - convertPixelsToMilimeters(32),
    doc.getPageHeight() - convertPixelsToMilimeters(64));
}

const addHeaderFooterDetector = (doc: any, detector: any = null) => {
  addHeaderFooter(doc)
  //Footer text
  let date = new Date();
  let month = date.toLocaleString("default", { month: "long" });
  let year = date.getFullYear();

  doc.setFontSize(10);

  doc.text(`Date: ${year} ${month}`, convertPixelsToMilimeters(32), doc.getPageHeight() - convertPixelsToMilimeters(18));
  doc.setFont("Helvetica-TTF", "bold");

  const valuesInBold = `${i18n.t("translation:SensorData.export.theValuesInBold")}`;
  const widthInPixelsForValuesInBold = doc.getTextWidth(valuesInBold);
  let xg = 0;
  if (widthInPixelsForValuesInBold > 100) {
    xg = widthInPixelsForValuesInBold - 100;
  }
  doc.text(200 - widthInPixelsForValuesInBold + xg, doc.getPageHeight() - convertPixelsToMilimeters(45), valuesInBold, { maxWidth: 100 });
  //printOnTwoRowsIfNeeded(doc, valuesInBold, 83, 200-widthInPixelsForValuesInBold+xg, doc.getPageHeight() - convertPixelsToMilimeters(45), doc.getPageHeight() - convertPixelsToMilimeters(35));

  if (detector) {
    const { name } = detector.sensorInformation;
    const { projectName } = detector;
    let macAddress = returnMacFormatted(detector.macAddress);
    const customName = detector.customName? detector.customName : '-';

    doc.setFontSize(12);
    doc.setTextColor(26, 26, 26);
    doc.setFont("Helvetica-TTF", "bold");

    let maxLenForLineForDetectorTypeFormatted = 48;
    let detectorTypeFormatted = getDetectorTypeFormatted(detector);

    if (detector.projectName != undefined) {
      //printOnTwoRowsIfNeeded(doc, detectorTypeFormatted, maxLenForLineForDetectorTypeFormatted, 105, convertPixelsToMilimeters(32), convertPixelsToMilimeters(48));
      doc.text(detectorTypeFormatted, convertPixelsToMilimeters(297), convertPixelsToMilimeters(31), { maxWidth: convertPixelsToMilimeters(273)});
      doc.text(customName, convertPixelsToMilimeters(297), convertPixelsToMilimeters(46));
    }

    doc.setFont("Helvetica-TTF", "normal");

    //if (detector.projectName != undefined) {
    if (isExportingProjects() || detector.projectName != undefined) {
      doc.text((projectName) ? projectName : "", convertPixelsToMilimeters(135), convertPixelsToMilimeters(42));

      doc.setTextColor(76, 76, 76);
      doc.text(name, convertPixelsToMilimeters(297), convertPixelsToMilimeters(64));
      doc.text((macAddress) ? macAddress : "", convertPixelsToMilimeters(297), convertPixelsToMilimeters(80));
    }
    else {
      doc.setFontSize(12);
      doc.setTextColor(54, 54, 54);
      doc.setFont("Helvetica-TTF", "normal");
      if(detector.templateName !== undefined){
        doc.text(detector.templateName, convertPixelsToMilimeters(135), convertPixelsToMilimeters(42));
      } else
      if(detector.customName !== undefined){
        doc.text(detector.customName, convertPixelsToMilimeters(135), convertPixelsToMilimeters(42));
      }
    }
    //console.log(detector)
  }
}

const addHeaderFooterTable = (doc: any) => {
  addHeaderFooter(doc, true);

  doc.setFontSize(10);
  doc.setTextColor(54, 54, 54);
  doc.setFont("Helvetica-TTF", "normal");
  doc.text(`${i18n.t("translation:SensorData.export.tableOfContents")}`, convertPixelsToMilimeters(135), convertPixelsToMilimeters(42));

  //Footer text
  let date = new Date();
  let day = date.getDate();
  let month = date.toLocaleString("default", { month: "long" });
  let year = date.getFullYear();

  let dayOrdinal = (day: number) => {
    switch (day % 10) {
      case 1: return "st"
      case 2: return "nd"
      case 3: return "rd"
      default: return "th"
    }
  }

  const reportExportedOn = `${i18n.t("translation:SensorData.export.reportExportedOn")}: ${month} ${day}${dayOrdinal(day)} ${year}`;
  const widthInPixelsForReportExportedOn = doc.getTextWidth(reportExportedOn);
  doc.text(reportExportedOn, 200 - widthInPixelsForReportExportedOn, doc.getPageHeight() - convertPixelsToMilimeters(45));
}

// Use till is is not used anywhere else and replaced with maxWidth parameter of doc.text instead
const printOnTwoRowsIfNeeded = (doc: any, text: string, maxLengthAllowed: number, xPos: number, yPosFirst: number, yPosSecond: number) => {
  if (text.length >= maxLengthAllowed) {
    let words = text.trim().split(/\s+/);
    if (words.length === 1) {
      doc.text(text.substring(0, maxLengthAllowed), xPos, yPosFirst);
      doc.text(text.substring(maxLengthAllowed), xPos, yPosSecond);
    } else {
      let firstLine = "";
      firstLine += (words[0] + " ");
      let idx = 1;
      while (idx < words.length && firstLine.length + words[idx].length + 1 < maxLengthAllowed) {
        firstLine += (words[idx] + " ");
        ++idx;
      }
      doc.text(firstLine, xPos, yPosFirst);

      let secondLine = "";
      while (idx < words.length) {
        secondLine += (words[idx] + " ");
        ++idx;
      }
      doc.text(secondLine, xPos, yPosSecond);
    }
  } else {
    doc.text(text, xPos, yPosFirst);
  }
}



const countNeededRows = (properties: any, index: number, currentHeight: number, doc: any) => {
  let lengthArr = [];
  let k = (properties[index].style === "h1");

  let additionalHeight = 0;

  for (let i = index + 1; i < properties.length; i++) {
    lengthArr.push(properties[i]);

    if (properties[i].style === "h2" || properties[i].style === "h1") {
      if (k) {
        k = false;
      } else {
        break;
      }
    }

    if (properties[i].type === 'lightSceneLuxLevel') {
      additionalHeight += 20;
    } else if (properties[i].style === 'h2') {
      additionalHeight += 10;
    } else if (properties[i].style === 'h3') {
      additionalHeight += 7;
    }
  }

  let neededHeight = (lengthArr.length * 8) + additionalHeight;
  let remainingHeight = doc.getPageHeight() - currentHeight - 30;

  if (neededHeight > remainingHeight) {
    return true;
  }
}




const generatePage = (doc: any, detector: any, isDetectorsetting: boolean): void => {
  const detectorObj = GetDefaultDescription(detector, isDetectorsetting);
  addHeaderFooterDetector(doc, detector);

  // console.log(detector)
  const secondRowX = convertPixelsToMilimeters(355); //105mm;
  let height = convertPixelsToMilimeters(75);

  if (!isDetectorsetting) // is template?
  {
    height += convertPixelsToMilimeters(56);
    doc.setFontSize(14);
    doc.setTextColor(30, 30, 30);
    doc.setFont("Helvetica-TTF", "bold");
    doc.text(`${i18n.t(`translation:Templates_Receivedtemplates.tableHeadings.templateDetails`)}`, convertPixelsToMilimeters(32), height);

    height += convertPixelsToMilimeters(41);
    doc.setFontSize(14);
    doc.setTextColor(26, 26, 26);
    doc.text(`${i18n.t(`translation:Templates_Receivedtemplates.tableHeadings.detectorType`)}:`, convertPixelsToMilimeters(32), height);
    doc.text(getDetectorTypeFormatted(detector), convertPixelsToMilimeters(355), height, { maxWidth: 75 });

    //height += heightToAdd(doc, getDetectorTypeFormatted(detector), 75, 5);

    if ('receivedFrom' in detector) {
      height += convertPixelsToMilimeters(40);
      doc.text(`${i18n.t(`translation:Templates_Receivedtemplates.tableHeadings.receivedFrom`)}:`, convertPixelsToMilimeters(32), height);
      doc.text(`${detector.receivedFrom}`, secondRowX, height, { maxWidth: convertPixelsToMilimeters(208) });
    }

    if ('receivedOn' in detector) {
      height += convertPixelsToMilimeters(32);
      doc.text(`${i18n.t(`translation:Templates_Receivedtemplates.tableHeadings.receivedDate`)}:`, convertPixelsToMilimeters(32), height);
      doc.text(`${moment.tz(detector.receivedOn, moment.tz.guess()).format("DD/MM/YYYY")}`, secondRowX, height, { maxWidth: convertPixelsToMilimeters(208) });
    }
  }

  if (detectorObj !== undefined) {

    sortLightSceneGroupsByPercentage(detectorObj);

    Object.entries(detectorObj).forEach((tab: any, i: number) => {
      let [tabName, properties] = tab;
      if (tabName !== "tabs") {

        let showTitle = true;
        const addTitle = () => {
          height += convertPixelsToMilimeters(58);
          doc.setFontSize(14);
          doc.setTextColor(30, 30, 30);
          doc.setFont("Helvetica-TTF", "bold");
          doc.text(Object.entries(detectorObj.tabs)[i][1], convertPixelsToMilimeters(32), height);
          showTitle = false;
        }

        //let nextItemStyle = 'h1';
        properties.forEach((item: any, i: number) => {
          //console.log('Height: ', height, '   \n\n  item: ', item);

          if ((item.style === "h1" || item.style === "h2") && countNeededRows(properties, i, (showTitle) ? height + convertPixelsToMilimeters(40) : height, doc)) {
            //console.log('AAAAAAAAAAAAAA :', countNeededRows(properties, i, (showTitle) ? height + convertPixelsToMilimeters(40) : height, doc));
            doc.addPage();
            addHeaderFooterDetector(doc, detector);
            height = convertPixelsToMilimeters(75);
            if (showTitle) addTitle();
          } else {
            if (showTitle) addTitle()
          }

          if (item.style === "h1" || item.style === "h2") {
            if (properties[i+1] != undefined && properties[i+1].style === 'h3') {
              let pixelsIfTextOnMoreRows = 725 - (32 * (getTextLines(doc, item.label, convertPixelsToMilimeters(306))));
              //console.log(height, ' ', convertPixelsToMilimeters(pixelsIfTextOnMoreRows));

              if (height > convertPixelsToMilimeters(pixelsIfTextOnMoreRows)) {
                doc.addPage();
                addHeaderFooterDetector(doc, detector);
                height = convertPixelsToMilimeters(75);
              }
            }
          }

          if (item.style === "h3"){
            let pixelsIfTextOnMoreRows = 725 - (32 * (getTextLines(doc, item.label, convertPixelsToMilimeters(306))-1))
            if (height > convertPixelsToMilimeters(pixelsIfTextOnMoreRows)) {
              doc.addPage();
              addHeaderFooterDetector(doc, detector);
              height = convertPixelsToMilimeters(75);
            }
          }

          if (item.style === "h1") {
            // Example Space after "Settings" on project
            if (height == convertPixelsToMilimeters(75)) {
              height += convertPixelsToMilimeters(58);
            }
            else {
              height += convertPixelsToMilimeters(32);
            }
            doc.setFontSize(14);
            doc.setTextColor(26, 26, 26);
            doc.setFont("Helvetica-TTF", "bold");
            doc.text(`${item.label}`, convertPixelsToMilimeters(32), height);
            //console.log(`${item.label}`);

            doc.setTextColor(76, 76, 76);
            doc.setFont("Helvetica-TTF", "normal");
            let value = item.value.join(", ");
            // if (value.length > 35) {
            //   let splitedText = doc.splitTextToSize(value, 90);
            //   splitedText.map((row: string, index: number) => {
            //     (index === 0) ? height += 0 : height += convertPixelsToMilimeters(32);
            //     (item.isDefault) ? doc.setFont("Helvetica-TTF", "normal") : doc.setFont("Helvetica-TTF", "bold")
            //     doc.text(row, secondRowX, height, { maxWidth: convertPixelsToMilimeters(208) });
            //   });
            // } else {
            (item.isDefault) ? doc.setFont("Helvetica-TTF", "normal") : doc.setFont("Helvetica-TTF", "bold")
            doc.text(value, secondRowX, height, { maxWidth: convertPixelsToMilimeters(208) });
            //console.log(value);
            height += heightToAdd(doc, value, convertPixelsToMilimeters(208), convertPixelsToMilimeters(16));
            // }

            // if (item.type == 'zones') {
            //   height += convertPixelsToMilimeters(16);
            // }

          } else if (item.style === "h2") {
            if (height == convertPixelsToMilimeters(75)) {
              height += convertPixelsToMilimeters(58);
            }
            else {
              height += convertPixelsToMilimeters(32);
            }
            doc.setFontSize(14);
            doc.setTextColor(26, 26, 26);
            doc.setFont("Helvetica-TTF", "bold");
            doc.text(`${item.label}`, convertPixelsToMilimeters(32), height, { maxWidth: convertPixelsToMilimeters(306) });
            //console.log(item.label);

            doc.setTextColor(76, 76, 76);
            doc.setFont("Helvetica-TTF", "normal");
            (item.isDefault) ? doc.setFont("Helvetica-TTF", "normal") : doc.setFont("Helvetica-TTF", "bold")
            doc.text(`${item.value.join(", ")}`, secondRowX, height, { maxWidth: convertPixelsToMilimeters(208) });

          } else if (item.style === "h3") {
            if (height == convertPixelsToMilimeters(75)) {
              height += convertPixelsToMilimeters(58);
            }
            else {
              height += convertPixelsToMilimeters(32);
            }
            doc.setFontSize(14);
            doc.setTextColor(76, 76, 76);
            doc.setFont("Helvetica-TTF", "normal");
            let label = item.label;

            if (label.length > 40) {
              let splitedText = doc.splitTextToSize(label, convertPixelsToMilimeters(306));
              splitedText.map((row: string, index: number) => {
                (index == 0) ? height += 0 : height += convertPixelsToMilimeters(32);
                doc.text(row, convertPixelsToMilimeters(32), height);
                //console.log(row);
              });
            } else {
              // replace fancy quotes with normal ones as they cause trouble when printed
              label = label.replace(/[“”‘’„]/g, '\'');
              doc.text(label, convertPixelsToMilimeters(32), height);
            }

            let value = item.value.join(", ");
            if (item.type == `lightSceneLuxLevel` &&
              (
                value.includes(`${i18n.t("translation:SensorData.functions.luminaire")}`) ||
                value.includes(`${i18n.t("translation:SensorData.functions.luminaires")}`)
              )
            ) {
              //   item.value.forEach((el:any, index:number)=>{
              //     (index == 0) ? height += 0 : height+=7;
              //     (item.isDefault) ? doc.setFont("Helvetica-TTF", "normal") : doc.setFont("Helvetica-TTF", "bold")
              //     console.log(el);

              //     doc.text(el, secondRowX, height);
              //   })
              // }
              // else if (value.length > 40) {
              // let splitedText = doc.splitTextToSize(value, 70);
              // splitedText.map((row: string, index: number) => {
              let lightGroups = [];
              let lightGroup = '';

              for (let i = 0; i < item.value.length; i++) {
                if (i % 2 == 0) {
                  lightGroup = item.value[i];
                  continue;
                }
                lightGroup += ', ' + item.value[i];
                lightGroups.push(lightGroup);
              }
              lightGroups.forEach((row: string, index: number) => {
                (index == 0) ? height += 0 : height += convertPixelsToMilimeters(32);
                (item.isDefault) ? doc.setFont("Helvetica-TTF", "normal") : doc.setFont("Helvetica-TTF", "bold")
                doc.text(row, secondRowX, height, { maxWidth: convertPixelsToMilimeters(208) });
                //console.log('row : ', row);
              });
            } else {
              (item.isDefault) ? doc.setFont("Helvetica-TTF", "normal") : doc.setFont("Helvetica-TTF", "bold")
              doc.text(value, secondRowX, height, { maxWidth: convertPixelsToMilimeters(208) });
              //console.log('row : ', value);
              height += heightToAdd(doc, value, convertPixelsToMilimeters(208), convertPixelsToMilimeters(16));
            }
          }

        });
      }
    });
  }
};





let summaryTable: any = {
  summary: {},
  reset: function () {
    this.summary = {}
  },
  buildSummary: function (detector: any, page: any): void {
    //let type = getDetectorTypeFormatted(detector);

    // Export projects toc
    if (detector.projectName) {
      let project = detector.projectName;
      let type = (detector.sensorInformation.detectorFamily.includes("230V") ? "230V" : "DALI-2")

      if (!this.summary[project]) this.summary[project] = {}
      if (!this.summary[project][type]) this.summary[project][type] = []
      this.summary[project][type].push({
        detectorDescription: getDetectorTypeFormatted(detector),
        macAddress: detector.macAddress ? returnMacFormatted(detector.macAddress) : detector.templateName,
        customName: detector.customName? detector.customName : '-',
        page: page
      })
    }
    else { // Export templates toc
      let project = `${i18n.t(`translation:SensorData.export.detectors`)}`;
      //debugger;
      let type = detector.templateName || detector.customName;

      if (!this.summary[project]) this.summary[project] = {}
      if (!this.summary[project][type]) this.summary[project][type] = []
      this.summary[project][type].push({
        detectorDescription: `${i18n.t(`translation:Detectors_search.tableHeadings.detectorType`)}`,
        macAddress: getDetectorTypeFormatted(detector),
        page: page
      })

      if (detector.receivedFrom) {
        this.summary[project][type].push({
          detectorDescription: `${i18n.t(`translation:Templates_Receivedtemplates.tableHeadings.receivedFrom`)}`,
          macAddress: detector.receivedFrom,
          page: page
        })
      }

      if (detector.receivedOn) {
        this.summary[project][type].push({
          detectorDescription: `${i18n.t(`translation:Templates_Receivedtemplates.tableHeadings.receivedDate`)}`,
          macAddress: `${moment.tz(detector.receivedOn, moment.tz.guess()).format("DD/MM/YYYY")}`,
          page: page
        })
      }

      this.summary[project][type].push({
        detectorDescription: `${i18n.t(`translation:Templates_Receivedtemplates.tableHeadings.templateDetails`)}`,
        macAddress: "",
        page: page
      })
    }
  },
  addSummary: function (doc: any, isTemplate: boolean): void {
    doc.insertPage(1)
    addHeaderFooterTable(doc)

    //Calc summary length in rows
    let rowsCounter = Object.entries(this.summary).reduce((acc: any, project: any) => {
      let counter = Object.entries(project[1]).reduce((acc2: any, type: any) => {
        let counter = type[1].reduce((acc3: any) => {
          return acc3 + 1
        }, 1)
        return acc2 + counter
      }, 1)
      return acc + counter
    }, 0)
    let totalSummaryPages = Math.ceil(rowsCounter / 33)


    let pagesCounter = 1;
    let height = convertPixelsToMilimeters(75);
    Object.entries(this.summary).forEach((project: any) => {
      let [projectName, projectTypes] = project;
      if (doc.getPageHeight() - height < 50) {
        pagesCounter += 1;
        doc.insertPage(pagesCounter);
        addHeaderFooter(doc);
        height = convertPixelsToMilimeters(75);
      }
      if (projectName !== `${i18n.t(`translation:SensorData.export.detectors`)}`) {
        height += convertPixelsToMilimeters(32);
        doc.setFontSize(18);
        doc.setTextColor(26, 26, 26);
        doc.setFont("Helvetica-TTF", "bold")

        doc.text(projectName, convertPixelsToMilimeters(32), height, { maxWidth: convertPixelsToMilimeters(192) })
      }

      //DALI-2 will be printed first
      if (projectTypes["DALI-2"]) projectTypes = { "DALI-2": projectTypes["DALI-2"], ...projectTypes };

      Object.entries(projectTypes).forEach((type: any) => {
        let [typeName, typeValues] = type;
        if (doc.getPageHeight() - height < 45) {
          pagesCounter += 1;
          doc.insertPage(pagesCounter);
          addHeaderFooterTable(doc)
          height = convertPixelsToMilimeters(75);
        }
        height += convertPixelsToMilimeters(32);
        doc.setFontSize(14);
        doc.setTextColor(26, 26, 26);
        doc.setFont("Helvetica-TTF", "normal")

        if (isExportingDetectors()) {
          doc.setFont("Helvetica-TTF", "bold")
        }
        doc.text(typeName, convertPixelsToMilimeters(32), height)

        typeValues.forEach((entry: any) => {
          if (doc.getPageHeight() - height < 37) {
            pagesCounter += 1;
            doc.insertPage(pagesCounter);
            addHeaderFooterTable(doc)
            height = convertPixelsToMilimeters(75);
          }

          const trimToLengh = (text: string) => {
            return text.length > 25 ? text.substring(0, 25) + '...' : text;
          }
          const { detectorDescription, macAddress, page, customName } = entry
          const formatedCustomName = customName ? trimToLengh(customName) : '-';
          let currentPage = page + totalSummaryPages;

          height += convertPixelsToMilimeters(32);
          doc.setFontSize(14);
          doc.setTextColor(54, 54, 54)
          doc.setFont("Helvetica-TTF", "normal")
          if(isTemplate){
            doc.text(`${detectorDescription}`, convertPixelsToMilimeters(32), height, { maxWidth: convertPixelsToMilimeters(192) })
            doc.text(`${macAddress}`, convertPixelsToMilimeters(240), height, { maxWidth: 75 })
          } else{
            doc.text(`${detectorDescription}`, convertPixelsToMilimeters(32), height, { maxWidth: convertPixelsToMilimeters(192) })
            doc.text(formatedCustomName, convertPixelsToMilimeters(280), height,  { maxWidth: convertPixelsToMilimeters(120)})
            doc.text(`${macAddress}`, convertPixelsToMilimeters(400), height, { maxWidth: 75 })
          }

          height += getTextLines(doc, detectorDescription, convertPixelsToMilimeters(192)) > 1 ? convertPixelsToMilimeters(16) : 0;
          //doc.text(`${macAddress}`+`${macAddress}`, 120, height, {maxWidth: 75})

          const widthInPixelsForCurrentPage = doc.getTextWidth(`${currentPage}`);
          doc.text(`${currentPage}`, convertPixelsToMilimeters(555), height);

          //height += heightToAdd(doc, `${macAddress}`, 75, convertPixelsToMilimeters(16));
        })
      })
      if (isExportingProjects()) {
        height += convertPixelsToMilimeters(12);
      }
    })
  }
}

let addPageNumber = (doc: any): void => {
  doc.setFontSize(10);
  doc.setTextColor(54, 54, 54);
  doc.setFont("Helvetica-TTF", "normal");

  let numPages = doc.getNumberOfPages()
  for (let i = 1; i <= numPages; i++) {
    doc.setPage(i)
    doc.text(
      `${i18n.t("translation:SensorData.export.page")}: ${i}`,
      convertPixelsToMilimeters(32),
      doc.getPageHeight() - convertPixelsToMilimeters(44)
    );
  }
}

const addContactDetails = (doc: any, contactDetails: string): void => {
  doc.setFontSize(10);
  doc.setTextColor(54, 54, 54);
  doc.setFont("Helvetica-TTF", "normal");
  doc.setPage(1);
  doc.text(contactDetails, convertPixelsToMilimeters(32), doc.getPageHeight() - convertPixelsToMilimeters(20));
}

export const exportProjectsPDF = async (projects: IProject[], contactDetails: string) => {
  exportType = 'projects';
  
  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [297.1764705882353, 210],
  });
  
  doc.addFileToVFS('Helvetica-TTF-normal.ttf', HelveticaFont);
  doc.addFont('Helvetica-TTF-normal.ttf', 'Helvetica-TTF', 'normal');

  doc.addFileToVFS('Helvetica-TTF-bold.ttf', HelveticaFontBold);
  doc.addFont('Helvetica-TTF-bold.ttf', 'Helvetica-TTF', 'bold');

  summaryTable.reset();
  projects.forEach((project: IProject, i: number) => {
    if (i !== 0) doc.addPage();
    //console.log(i);
    console.log(project);
    // add Dali detectors first
    let v230Settings = project.sensorSettings.filter(setting => setting.sensorInformation?.detectorFamily.includes("230V"));
    let daliSettings = project.sensorSettings.filter(setting => setting.sensorInformation?.detectorFamily.includes("dali"));
    project.sensorSettings = daliSettings.concat(v230Settings);

    //Generate pages for each detector
    project.sensorSettings.forEach((detector: any, i: number) => {
      if (i !== 0) doc.addPage();
      summaryTable.buildSummary(detector, doc.getNumberOfPages()); //Create summary
      generatePage(doc, detector, true);
    });
  })
  summaryTable.addSummary(doc, false); //Add summary, it's a project
  addPageNumber(doc);
  //addContactDetails(doc, contactDetails);

  //Save PDF
  if (projects.length === 1) doc.save(`${projects[0].name}.pdf`);
  else doc.save(`Projects.pdf`);
};


export const exportDetectorsPDF = (
  detectors: ITemplateModel[] | IReceivedTemplateModel[] | IDetectorModel[] | ISensorSetting[],
  isDetectorsetting: boolean,
  contactDetails: string,
  title?: string,
): void => {
  exportType = 'detectors';

  const doc: any = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: [297.1764705882353, 210],
  });
//  let isTemplate:boolean ;

  doc.addFileToVFS('Helvetica-TTF-normal.ttf', HelveticaFont);
  doc.addFont('Helvetica-TTF-normal.ttf', 'Helvetica-TTF', 'normal');

  doc.addFileToVFS('Helvetica-TTF-bold.ttf', HelveticaFontBold);
  doc.addFont('Helvetica-TTF-bold.ttf', 'Helvetica-TTF', 'bold');

  summaryTable.reset();

  // add Dali detectors first
  detectors.sort((obj1: IDetectorModel | ITemplateModel | IReceivedTemplateModel | ISensorSetting,
    obj2: IDetectorModel | ITemplateModel | IReceivedTemplateModel | ISensorSetting) => {
    if (obj1.sensorInformation.detectorFamily.includes("dali") && obj2.sensorInformation.detectorFamily.includes("230V")) {
      return -1;
    }

    if (obj1.sensorInformation.detectorFamily.includes("230V") && obj2.sensorInformation.detectorFamily.includes("dali")) {
      return 1;
    }

    return 0;
  });

  //Generate PDF content
  detectors.forEach(
    (detector: IDetectorModel | ITemplateModel | IReceivedTemplateModel | ISensorSetting,
      i: number
    ) => {
      if (i !== 0) doc.addPage();
      summaryTable.buildSummary(detector, doc.getNumberOfPages()); //Create summary
      generatePage(doc, detector, isDetectorsetting);
    }
  );
  //if(detectors.length > 1)
  summaryTable.addSummary(doc, true); //Add summary, it's a template
  addPageNumber(doc);
  //addContactDetails(doc, contactDetails);

  //Save PDF
  if (title) {
    doc.save(`${title}.pdf`);
  } else if (detectors.length === 1)
    doc.save(`${detectors[0].sensorInformation.name}.pdf`);
  else doc.save(`Detectors.pdf`);
};

/* FONTS
Courier: (4) ["", "Bold", "Oblique", "BoldOblique"]
Helvetica: (4) ["", "Bold", "Oblique", "BoldOblique"]
Symbol: [""]
Times: (4) ["Roman", "Bold", "Italic", "BoldItalic"]
ZapfDingbats: [""]
courier: (4) ["normal", "bold", "italic", "bolditalic"]
helvetica: (4) ["normal", "bold", "italic", "bolditalic"]
humanistBD: ["bold"]
symbol: ["normal"]
times: (4) ["normal", "bold", "italic", "bolditalic"]
zapfdingbats: ["normal"] */
